import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Vimeo } from "@swizec/gatsby-theme-course-platform";
export const title = "Module 2";
export const description = "";
export const image = "/chapter_headers/serverless-elements.png";
export const _frontmatter = {};
const layoutProps = {
  title,
  description,
  image,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "user-dashboard"
    }}>{`User dashboard`}</h1>
    <p>{`Our user dashboard will say hello to our users, let them edit existing landing pages, and create new ones.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "890px",
          "textAlign": "center",
          "fontStyle": "italic"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/1382542de1d6ded172ada30f632f8169/b5a09/dashboard.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "51.12107623318386%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/svg+xml,%3csvg%20xmlns=\\'http://www.w3.org/2000/svg\\'%20width=\\'400\\'%20height=\\'205\\'%20viewBox=\\'0%200%20400%20205\\'%20preserveAspectRatio=\\'none\\'%3e%3cpath%20d=\\'M17%2016c1%207%202%209%203%203l1-3%201%203c1%204%203%204%205-1l2-3v3c0%207%202%204%202-3%200-8-2-8-5-1l-2%205-1-4-2-5-2-1c-2%200-2%201-2%207m130-5c0%201-1%202-2%201-3%200-5%202-5%205%200%204%202%206%206%206l4-1v-6l-1-7-2%202m34%205c0%205%200%206%202%206l1-2%202-2%204-2c1-1%202-2%203%200v6h5c5%200%204-10-2-10l-3%201-2-1c-1-3-2-3-6-3h-4v7m-14-3c-3%203%200%209%204%209l2%201h-4c-3%200-3%200-2%201%201%202%206%203%208%201l1-7v-6h-4l-5%201m36%200l-1%205c0%203%201%203%204%203l3%201-4%201c-3%200-3%200-2%201%202%202%205%202%207%200%202-1%202-3%202-6%200-5-1-5-4-6l-5%201M25%2066v7l1-7v-6h356v5l1%206V59H25v7m0%207v7l1%205h47v-6l1-7H50l-25%201\\'%20fill=\\'%23d3d3d3\\'%20fill-rule=\\'evenodd\\'/%3e%3c/svg%3e')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/1382542de1d6ded172ada30f632f8169/ca0a1/dashboard.webp 223w", "/static/1382542de1d6ded172ada30f632f8169/75680/dashboard.webp 445w", "/static/1382542de1d6ded172ada30f632f8169/8d1ba/dashboard.webp 890w", "/static/1382542de1d6ded172ada30f632f8169/3838e/dashboard.webp 1335w", "/static/1382542de1d6ded172ada30f632f8169/90e3c/dashboard.webp 1360w"],
              "sizes": "(max-width: 890px) 100vw, 890px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/1382542de1d6ded172ada30f632f8169/e92b6/dashboard.png 223w", "/static/1382542de1d6ded172ada30f632f8169/e66bf/dashboard.png 445w", "/static/1382542de1d6ded172ada30f632f8169/4ef49/dashboard.png 890w", "/static/1382542de1d6ded172ada30f632f8169/4e814/dashboard.png 1335w", "/static/1382542de1d6ded172ada30f632f8169/b5a09/dashboard.png 1360w"],
              "sizes": "(max-width: 890px) 100vw, 890px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/1382542de1d6ded172ada30f632f8169/4ef49/dashboard.png",
              "alt": "dashboard",
              "title": "dashboard",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "exercises"
    }}>{`Exercises`}</h2>
    <p>{`These are the exercises that will get you there:`}</p>
    <ol>
      <li parentName="ol">{`Add a `}<inlineCode parentName="li">{`createPage`}</inlineCode>{` mutation to your backend`}</li>
      <li parentName="ol">{`Connect it to the form on your dashboard`}</li>
      <li parentName="ol">{`Add an `}<inlineCode parentName="li">{`allPages`}</inlineCode>{` mutation to the backend`}</li>
      <li parentName="ol">{`Use it to fetch pages`}</li>
      <li parentName="ol">{`List those pages on the dashboard`}</li>
      <li parentName="ol">{`Re-fetch the list of pages for the dashboard on page load`}</li>
      <li parentName="ol">{`Create a Gatsby page for each landing page in `}<inlineCode parentName="li">{`gatsby-node.js`}</inlineCode></li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      